


* {
  margin: 0;



  --color-lightmode: #0b0b0b;
  --color-darkmode: white;
  --fontsize-regular: 15px;
  --myBold: 600;

  --introFontsizeReg: min(1.2vw, 2.4vh, 22px);
  --introLineHeightReg: min(1.9vw, 3.5vh, 28px);


}


* h4 {
  margin-bottom: 8px;
  font-weight: var(--myBold)
}

* h3 {
  margin-bottom: 20px;
  font-weight: var(--myBold);
  font-size: 15px;

}

* h5 {
  margin-bottom: 4px !important;
  font-size: 0.8rem;
  font-weight: var(--myBold)
}

.App {
  --background-lightmode: #795fb0;
  color: rgb(207, 255, 183);

  background-image: linear-gradient(to right, #924FAE, #7C58D4);
  width: 100vw;
  height: 100vh;
  /* was FCFCFC before */
  /* background-color: var(--background-lightmode); */
  color: var(--color-lightmode);
  /* font-family: Inconsolata; */


}



.page-videoReels,
.page-credits {

  /* background-image: linear-gradient(to right, #924FAE, #7C58D4); */
  /* background-image: url('./media/gradient.mp4');
  background-attachment: fixed;
  background-size: 100%; */
  background-color: transparent;
  min-height: calc(100vh - 0px);
  padding-top: 64px;
  font-family: Inter;
  display: flex;
  flex-direction: column;
  width: 100vw;
  min-height: calc(100vh - 64px);
  align-items: center;
  justify-content: flex-start;
  overflow-x: hidden;


}

.page-nologin {

  background-image: linear-gradient(to right, #924FAE, #7C58D4);
  /* background-image: url('./media/gradient.mp4');
  background-attachment: fixed;
  background-size: 100%; */
  background-color: transparent;
  min-height: calc(100vh - 0px);
  padding-top: 64px;
  font-family: Inter;
  display: flex;
  flex-direction: column;
  width: 100vw;
  min-height: calc(100vh - 64px);
  align-items: center;
  overflow-x: hidden;


}

.page-nologin p {
  color: white;
}

.nologin-logos {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin-bottom: 48px;
}

.intro-title-nologin {
  /* display: none; */
  width: 100%;
  /* background-color: yellow; */
  /* transition: 2s; */
  color: white;
  font-size: 40px;
  margin-bottom: 48px;
  /* background-color: red; */
  text-align: center;
  font-family: Inter;
  font-weight: 900;
  z-index: 0;
  background: -webkit-linear-gradient(right, #ad79c7, #ffffff);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

}

.pageContent {
  z-index: 30;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;


}


.page-main {
  position: absolute;
  top: 0px;
  left: 0px;
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: calc(100vh - 64px);
  padding-top: 64px;
  z-index: 50;


}



.bgVideo01 {
  display: none;
  position: fixed;
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
  z-index: 0;
}

.bgVideo02 {
  display: none;
  position: fixed;
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
  z-index: 0;

}


.login-dialogue {
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  line-height: 1.5;
  width: 80%;
}




/*-----------------------------------------------------------------------------------------------------------------------------------------*/
/* All things Navbar */


.navbar {
  background-color: 'transparent';
  height: 64px;
  max-width: 100vw;
  overflow: hidden;
  position: fixed;
  width: 100%;
  top: 0px;
  z-index: 300;
  display: flex;
  flex-direction: column;
  font-family: Inter;
  transition: 0.3s;

}



.navbar-row1 {
  /* background-image: linear-gradient(to right, rgba(145, 79, 174, 0.9), rgb(124, 88, 212, 0.9)); */
  height: 64px;
  max-width: 100%;
  display: flex;
  flex-direction: row;
  padding-left: 60px;
  padding-right: 60px;
  /* background-color: rgba(255, 255, 255, 0.99); */
  align-items: center;
  max-width: 100%;

}



.navbar a {
  /* color: rgb(173, 173, 173); */
  margin-right: 24px;
  text-decoration: none;
  font-size: 0.875rem;
}

.navbar a:hover {
  color: #181828;
}

.navbar span {
  color: #b0b0b0;
  cursor: default;
}

.navbar-row1-leftside {
  display: flex;
  width: 50vw;
  gap: 40px;
  font-size: 1rem;
  align-items: center;
}




.navbar-row1-rightside {

  display: flex;
  flex-direction: row;
  margin-left: auto;
  font-size: 0.7rem;
  height: 100%;
  align-items: center;


}


/* Page title*/
.navbar h1 {
  color: #181828;
  /* margin-left: auto; */
  /* margin-right: 3rem; */
  font-size: 1rem;
  font-weight: bold;
  -webkit-user-select: none;
  /* Safari */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* IE10+/Edge */
  user-select: none;
  /* Standard */
  cursor: default;

}

.burger-icon {
  width: 22px;
  height: 22px;
  cursor: pointer;
}

.logout-icon {
  width: 18px;
  height: 18px;
  cursor: pointer;
  margin-left: 12px;
  /* opacity: 40%; */
}

.gemic-logo {
  width: calc(0.02*2928px);
  height: calc(0.02*687px);
  /* max-width: 2rem; */
  margin-right: 24px;


}

/* + */
.navbar p {
  /* margin-left: 2.5rem; */
  font-size: 0.875rem;
  font-weight: bold;
  margin-left: 24px;
  cursor: default;


}

.spotify-logo {
  width: calc(0.06*1024);
  height: calc(0.06 *308px);
  margin-left: 24px;
}

.navbar-rightsidelinks {
  color: rgb(173, 173, 173);
  text-decoration: none;
  font-size: 0.875rem;
  margin-left: 24px;
  cursor: pointer;
}

.toggling-icons {
  display: none;
  width: 18px;
  height: 18px;
}

.navbar-rightsidelinks:hover {
  color: #181828;

}

.navbar-logoutText {
  color: #bcaed8;
  font-size: 0.8rem;

}

.navbar-viewToggleUnit {
  display: flex;
  align-items: center;
}

.navbar-expText {
  color: white;
  margin-left: 48px;
  font-weight: var(--myBold);


}

.navbar-prodText {
  color: #bcaed8;
  margin-left: 12px;
  font-weight: var(--myBold);

}

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 49.5px;
  height: 22px;
  margin-left: 12px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: transparent;
  border: 1px solid white;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 18px;
  width: 18px;
  left: 2px;
  bottom: 1px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked+.slider {
  background-color: transparent;
  border: 1px solid black;

}

input:focus+.slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked+.slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
  background-color: black;
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}


/* Sidebars */


.sidebarLeft {

  position: fixed;
  font-family: Inter;
  top: 0px;
  left: -374px;
  height: 100vh;
  background-color: #F6FFE9;
  width: 374px;
  align-items: left;
  font-size: 0.8rem;
  transition: 0.5s;
  z-index: 600;
}

.sidebarLeft-content {
  margin-left: 60px;
  margin-right: 12px;
  margin-top: 12px;
  display: flex;
  height: 100%;
  flex-direction: column;


}



.sidebarLeft-closebutton {
  font-size: 2rem;
  cursor: pointer;
  margin-bottom: 48px;

}

.sidebarLeft h2 {
  margin-bottom: 3vh;
  font-weight: var(--myBold)
}

.sidebarLeft h2:hover {
  color: #6580EF;
}

.sidebarLeft-logoContainer {
  display: flex;
  flex-direction: row;
  margin-top: auto;
  margin-bottom: 34px;
  align-items: center;
}

.sidebarLeft-gemic-logo {
  width: calc(0.03*2928px);
  height: calc(0.03*687px);
  /* max-width: 2rem; */
  margin-right: 12px;


}

.sidebarLeft-spotify-logo {
  width: calc(0.09*1024);
  height: calc(0.09 *308px);
  margin-left: 12px;
}

.sidebar-anchorcolumn {
  display: none;
  flex-direction: column;
  background-color: transparent;
  width: 30px;
  height: 100%;
}

.sidebar-anchor {
  display: none;
  height: 70px;
  width: 100%;
  border-radius: 0px 0px 8px 0px;
  background-color: rgba(240, 240, 240, 0.985);
  /* border-right: 1px solid #e6e6e6;
  border-top: 1px solid #e6e6e6;
  border-bottom: 1px solid #e6e6e6; */



  cursor: pointer;

}

.sidebar-anchor-button {
  display: none;
  width: 12px;
  height: 12px;
  margin: auto;
  /* opacity: 80%; */

}

.sidebar-anchor span {
  margin: auto;
  font-family: 'Inter';
  font-size: 1.5rem;
  color: white;

}

.sidebar-aboveanchor {
  display: none;
  width: 100%;
  height: 0px;

}


.sidebarRight {

  font-family: Inter;
  position: fixed;
  top: 0px;
  right: -533px;
  height: calc(100vh);
  /* background-color: #161414; */
  /* background-image: linear-gradient(#FF978C, #FFEEB6); */
  /* background-attachment: fixed;
  background-repeat: no-repeat; */
  width: 533px;
  /* align-items: center; */
  /* justify-content: center; */
  font-size: 1rem;
  transition: 0.6s ease-in;
  z-index: 400;
  font-size: var(--fontsize-regular);
  line-height: 1.4;
  overflow-y: auto;
  /* background-color: #FFEEB6; */
  background-image: linear-gradient(#FF978C, #FFEEB6);


}

.sidebarRight-container {
  width: 100%;
  background-image: linear-gradient(#FF978C, #FFEEB6);
}

.sidebarRight h2 {
  font-weight: var(--myBold);
  font-size: 25px;
  color: black;
}

.sidebarRight h3 {
  color: black;

}



.sidebarRight-content {
  display: flex;
  flex-direction: column;
  margin: 54.5px;

}

.sidebarRight-closebutton {
  background-color: #F6FFE9;
  position: fixed;
  margin-left: 12px;
  margin-top: 12px;
  color: #434343;
  color: #795FB0;
  height: 22px;
  width: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem !important;
  cursor: pointer;

}

.sidebarRight-shiftImage {
  width: 100%;
  height: 40%;
  object-fit: cover;
  object-position: middle;
  background-color: lightgrey;


}

.sidebarRight-fromToContainer {
  color: black;
  margin-top: 40px;
  margin-bottom: 18px;

}

.sidebarRight-shiftRow {
  display: flex;
  flex-direction: row;
  margin-bottom: 12px;
  min-height: 70px;
}

.sidebarRight-shiftRow h3 {
  margin-bottom: 12px !important;

}

.sidebarRight-shiftRowLeft {
  width: 20%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  /* background-color: red; */
  /* background-color: red; */

}


.sidebarRight-fromToLine {
  position: relative;
  min-height: 35px;
  height: 100%;
  border-left: 3px solid rgb(0, 0, 0);
  margin-left: 6px;

}

.sidebarRight-fromToDot {
  position: absolute;
  height: 12px;
  width: 12px;
  bottom: 0px;
  left: -7.5px;
  background-color: rgb(0, 0, 0);
  border-radius: 50%;

}

.sidebarRight-shiftRowRight {
  width: 80%;
}

.sidebarRight-description {
  margin-bottom: 40px;
  color: black;

}

.sidebarRight-whiteBox {
  background-color: white;
  /* background-color: #e5eed9; */
  margin-bottom: 80px;
  padding: 28px;
  border-radius: 12px;
}

.sidebarRight-whiteBox :last-child {
  margin-bottom: 0px;
}

.sidebarRight-boxLine {
  position: relative;
  height: 30px;
  border-left: 3px solid rgb(0, 0, 0);
  margin-left: 6px;
  margin-top: 15px;
  margin-bottom: 15px;

}

.sidebarRight-boxLineDot {
  position: absolute;
  height: 12px;
  width: 12px;
  bottom: 0px;
  left: -7.5px;
  background-color: black;
  rotate: 45deg;

}

.sidebarRight-whiteBox-2Columns {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column-reverse;
  margin-bottom: 30px;
  gap: 15px;
}


.sidebarRight-whiteBox-2Columns-text {
  width: calc(100% - 2rem);
  font-size: 0.8rem;
  border: 1px solid black;
  border-style: dashed;
  border-radius: 10px;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: left;
}

.sidebarRight-whiteBox-2Columns-image {
  background-color: #E9E9E9;
  border-radius: 12px;
  width: 100%;
  height: 200px;
  object-fit: cover;
  /* object-position: left; */
}

/* Main page */


.page-main p {

  align-self: center;
  margin-top: 28px;
  margin-bottom: 28px;

}

.page-main a,
.page-credits a {
  text-decoration: none;
  color: rgb(196, 196, 196);
}

.page-marketStudies a {
  text-decoration: underline;
  color: white;
}


.page-main a:hover,
.page-others a:hover,
.page-credits a:hover,
.page-marketStudies a:hover {
  color: white;
}


.loading-icon {
  position: absolute;
  width: 120px;
  height: 120px;
  z-index: 300;
  top: calc((100vh/2) - 60px);
  left: calc((100vw / 2) - 60px)
}

.graph {
  /* background-color: rgb(240, 240, 240); */
  height: calc(100vh - 64px);
  width: calc(100% - 0rem);
  align-self: center;

}

.graph-legend-insights {
  background-color: #795FB0;
  position: absolute;
  width: 140px;
  height: 120px;
  right: 12px;
  bottom: 12px;
  /* background-color: yellow; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  border: 0.5px solid white;
  border-radius: 12px;
  z-index: 300;
  padding-left: 18px;

}

.graph-legend-implications {
  display: none;
  background-color: #D1C1D4;
  position: absolute;
  width: 140px;
  height: 120px;
  right: 12px;
  bottom: 12px;
  /* background-color: yellow; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  border: 0.5px solid black;
  border-radius: 12px;
  z-index: 300;
  padding-left: 18px;

}



.graph-legend-row {
  height: 30%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.graph-legend-node1 {
  height: 25px;
  width: 25px;
  background-color: #FFE41E;
  border-radius: 50%;
  margin-right: 30px;
}

.graph-legend-node1-txt {
  color: #FFE41E;
}

.graph-legend-node2 {
  height: 25px;
  width: 25px;
  background-color: #CFFFB7;
  border-radius: 50%;
  margin-right: 30px;
}

.graph-legend-node2-txt {
  color: #CFFFB7;
}

.graph-legend-node3 {
  height: 25px;
  width: 25px;
  background-color: #FFFFFF;
  border-radius: 50%;
  margin-right: 30px;
}

.graph-legend-node3-txt {
  color: #FFFFFF;
}

.graph-tutorial {
  /* position: relative; */
  font-family: Inconsolata !important;
  font-size: 1.15vw !important;
  /* font-size: 15px; */
  color: #9d9d9d;
  font-family: Inter;
  display: none;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 80vw;
  height: 100px;
  /* width: 0vw;
  height: 0px; */
  background-color: rgba(42, 29, 68, 0.95);
  bottom: 40px;
  left: calc(20vw / 2);
  /* bottom: 34px;
  left: 70px; */
  border-radius: 18px;
  transition: 0.4s ease-in;
  z-index: 500;

  color: white;

  /* -webkit-animation: fadeIn 0.5s;
  animation: fadeIn 0.5s;
  transition: opacity 1s ease-out; */
  /* -webkit-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  -moz-transform: scale(1);
  transform: scale(1); */

}

.graph-tutorial-content {
  display: none;
  flex-direction: row;
  /* width: 85%; */
  /* background-color: green; */
  height: 100%;
  align-items: center;
  justify-content: center;
}


.graph-tutorial-column1 {
  /* width: 33%; */
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.graph-tutorial-column2 {
  /* width: 40%; */
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.graph-tutorial-column3 {
  /* width: 26%; */
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.graph-tutorial-columnRow1 {
  width: 100%;
  height: 50%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.graph-tutorial-dottedLine {
  border-bottom: 1px dashed #8361CD;
  position: absolute;
  top: 50px;
  left: 3vw;
  width: 74vw;
}

.graph-tutorial-columnRow2 {
  width: 100%;
  height: 50%;
  display: flex;
  flex-direction: row;
  align-items: center;

}

.tutorialNode {
  width: 22px;
  height: 22px;
  border-radius: 50%;
}

.graph-helpIcon {
  display: none;
  position: absolute;
  bottom: 24px;
  left: 56px;
  cursor: pointer;
  z-index: 50;
}

.graph-helpIcon-banner {
  display: unset;
  margin-right: 24px;
}

.popup-signal,
.hover-signal {
  font-family: Inconsolata;
  position: absolute;
  width: calc(0.9*273px);
  height: calc(0.9*234px);
  display: none;
  z-index: 300;
  /* background-color: green; */
  /* border: 1px solid black; */
  flex-direction: column;
  align-items: center;

}

.popup-signal-bgImage {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
}

.popup-signal-buttonUnit {
  display: flex;
  flex-direction: row;
  position: absolute;
  top: 4px;
  right: 4px;
  gap: 3px;
}

.popup-signal-button {
  width: 16px;
  height: 16px;
}

.popup-signal-button-link {
  width: 16px;
  height: 16px;
  cursor: pointer;

}

.popup-need-button-max,
.popup-opp-button-max {
  width: 16px;
  height: 16px;
  cursor: pointer;

}


.popup-driver-button-max,
.popup-signal-button-max {
  width: 16px;
  height: 16px;
}



.popup-signal-whitebox {
  position: absolute;
  top: 27px;
  left: 6px;
  /* background-color: yellow; */
  width: calc(0.85*273px);
  height: calc(0.75*234px);
  z-index: 100;

  overflow-y: auto;

}

.popup-description,
.hover-description {
  font-size: 0.8rem !important;
  line-height: 1.2;
  padding: 14px;
}

.popup-image {
  width: 100%;
  margin-bottom: 8px;
}

.popup-image-placeholder,
.hover-image-placeholder {
  width: 90%;
  height: 60px;
  background-color: lightgray;
  margin-bottom: 8px;
  font-size: 0.6rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.popup-bottomRow {
  font-size: 0.6rem;
  width: calc(100% - 16px);
  height: 12px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 8px;
}

.popup-bottomRow-close {
  font-size: 1rem;
  margin-left: 14px;
  cursor: pointer;
}


.popup-driver {
  font-family: Inconsolata;

  position: absolute;
  width: calc(0.9*273px);
  height: calc(0.9*163px);
  display: none;
  z-index: 300;
  /* background-color: green; */
  /* border: 1px solid black; */
  flex-direction: column;
  align-items: center;

}

.popup-driver-bgImage {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
}

.popup-driver-buttonUnit {
  display: flex;
  flex-direction: row;
  position: absolute;
  top: 4px;
  right: 4px;
  gap: 3px;
}

.popup-driver-button {
  width: 16px;
  height: 16px;
}

.popup-driver-button-link {
  width: 16px;
  height: 16px;
  cursor: pointer;

}



.popup-driver-whitebox {
  position: absolute;
  top: 27px;
  left: 6px;
  /* background-color: yellow; */
  width: calc(0.85*273px);
  height: calc(0.47*234px);
  z-index: 100;

  overflow-y: auto;

}

.popup-description,
.hover-description {
  font-size: 0.6rem;
  padding: 14px;
}

/* Vis graph */


/* Legacy stuff from React defaults */




.App button {
  background-color: #1DB954;
  border-radius: 8px;
  border-style: none;
  box-sizing: border-box;
  color: #FFFFFF;
  cursor: pointer;
  display: inline-block;
  font-size: 14px;
  font-weight: 500;
  height: 40px;
  line-height: 20px;
  list-style: none;
  margin: 0;
  outline: none;
  padding: 10px 16px;
  position: relative;
  text-align: center;
  text-decoration: none;
  transition: color 100ms;
  vertical-align: baseline;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  margin-bottom: 20px;
}

.button-1:hover,
.button-1:focus {
  background-color: #F082AC;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-main {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App-main p {
  max-width: 600px;
  text-align: left;
  line-height: 1.3;
  font-size: 1.2rem;
}



.hiddenstuff {
  margin-top: 88px;

}

.App-link {
  color: #61dafb;
}




@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

/* Add animation (fade in the popup) */
@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

/* Animations */

@keyframes shake {
  0% {
    transform: translate(1px, 1px) rotate(0deg);
  }

  10% {
    transform: translate(-1px, -2px) rotate(-1deg);
  }

  20% {
    transform: translate(-3px, 0px) rotate(1deg);
  }

  30% {
    transform: translate(3px, 2px) rotate(0deg);
  }

  40% {
    transform: translate(1px, -1px) rotate(1deg);
  }

  50% {
    transform: translate(-1px, 2px) rotate(-1deg);
  }

  60% {
    transform: translate(-3px, 1px) rotate(0deg);
  }

  70% {
    transform: translate(3px, 1px) rotate(-1deg);
  }

  80% {
    transform: translate(-1px, -1px) rotate(1deg);
  }

  90% {
    transform: translate(1px, 2px) rotate(0deg);
  }

  100% {
    transform: translate(1px, -2px) rotate(-1deg);
  }
}

@keyframes grow {
  0% {
    transform: scale(10)
  }

  10% {
    transform: scale(10)
  }

  20% {
    transform: scale(10)
  }

  30% {
    transform: scale(10)
  }

  40% {
    transform: scale(10)
  }

  50% {
    transform: scale(10)
  }

  60% {
    transform: scale(10)
  }

  70% {
    transform: scale(10)
  }

  80% {
    transform: scale(10)
  }

  90% {
    transform: scale(10)
  }

  100% {
    transform: scale(10)
  }
}

/* Scroll jacking tests */

.testo00 {

  font-size: 4rem;

  display: flex;
  justify-content: center;

}

/* Framer Motion test*/
/* .testo {

  font-size: 4rem;
  background-image: url('./media/stars.jpg');
  background-size: contain;
  width: 100vw;
  height: 400vh;
  display: flex;
  align-items: top;
  padding-top: 80vh;
  justify-content: center;
  color: white;

} */

/* Elements "rolling in" from a side */

.rollInContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100vw;
  gap: 20px;
  padding-top: 12px;
  padding-bottom: 12px;
}

.rollInFromLeftElement {
  width: 100px;
  height: 100px;
  font-size: 0.8rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: contain;
  opacity: 0;
}

.rollInFromRightElement {
  align-self: flex-end;
  width: 100px;
  height: 100px;
  font-size: 0.8rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: contain;
  opacity: 0;

}


/* Black hole switching bg effect */

.page-animations {

  font-family: Inter;
  display: flex;
  flex-direction: column;
  width: 100vw;
  min-height: calc(100vh - 64px);
  align-items: center;
  justify-content: center;
  background-size: 100%;
  overflow-x: hidden;

}


.hiddenBG {
  width: 200vw;
  height: 200vh;
  background-size: 50%;
  background-position: center;
  z-index: 1;
  position: fixed;
  top: -50vh;
  left: -50vw;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
}

.blackHole {
  border-radius: 100%;
  width: 0px;
  height: 0px;
  /* margin-left: 20%; */
  margin-top: 10%;
  box-shadow: 0 0 0 9999px rgba(121, 95, 176, 1);
  ;
  opacity: 0;
}

/* Switching slides effect */



.slideContainer {
  background-color: #795FB0;
  position: fixed;
  opacity: 0;
  top: 0;
  left: 0;
  align-self: flex-start;
  height: 100vh;
  /* define width depending on amount of slides (100vw each)*/
  /* width: 400vw; */
  background-color: 'transparent';
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

}

.slideShow {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  position: relative;
  scale: 1;
}

.slideShowAbove {
  height: 100%;
  width: 100%;
  opacity: 50%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  position: relative;
}

.allSlides {
  position: absolute;
  display: flex;
  flex-direction: row;
  left: 0%;
  width: 100%;
  height: 100%;

}

.slide {
  width: 100vw;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  font-size: 3rem;
  overflow-y: hidden;
}

/* Moving text effect*/

.movingTextContainer {
  width: 100vw;
  height: 30vh;
  color: white;
  font-size: 3rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  margin-bottom: 100vh;
  /* background-color: red; */
}

.movingTextLeftRight {
  position: absolute;
  /* background-color: green; */
  text-align: right;
  width: 100%;
  left: -100%;
  top: 10%;
}

.movingTextRightLeft {
  position: absolute;
  /* background-color: yellow; */
  width: 100%;
  left: 100%;
  top: 50%;
}



/* Sticky text */


.stickyText {
  bottom: 0;
  left: 5vw;
  opacity: 0;
  position: fixed;
  width: 90vw;
  text-align: center;
  color: white;
  font-size: 3rem;


}





/* Sticky text moving */


.stickyTextMovingLeftRight {
  left: -100%;
  opacity: 0;
  position: fixed;
  width: 100vw;
  text-align: right;
  color: white;
  font-size: 3rem;
  /* background-color: red; */


}

.stickyTextMovingRightLeft {
  left: 100%;
  opacity: 0;
  position: fixed;
  width: 100vw;
  text-align: left;
  color: white;
  font-size: 3rem;
  /* background-color: red; */


}

.stickyMovingTrigger {
  /* margin-top: 80vh; */
  /* margin-bottom: 80vh; */

}





/* ---Intro -------*/

.page-intro {

  /* background-color: var(--background-lightmode); */
  background-image: linear-gradient(to right, #924FAE, #7C58D4);
  /* background-image: url('./media/gradient.mp4');
  background-attachment: fixed;
  background-size: 100%; */
  font-family: Inter;
  display: flex;
  flex-direction: column;
  width: 100vw;
  min-height: calc(100vh - 64px);
  align-items: center;
  justify-content: top;
  background-size: 100%;
  overflow-x: hidden;
  z-index: 900;
  margin-top: calc(46vw - 64px);



}

.page-intro a {
  color: white;
  text-decoration: underline;

}

.page-intro-portraitMode {

  /* background-color: yellow; */
  background-image: linear-gradient(to right, #924FAE, #7C58D4);
  /* background-image: url('./media/gradient.mp4');
  background-attachment: fixed;
  background-size: 100%; */
  font-family: Inter;
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh !important;
  align-items: center;
  justify-content: center;
  background-size: 100%;
  overflow-x: hidden;
  z-index: 299;
  margin-top: calc(46vw - 64px);
  position: fixed;
  bottom: 0%;
  left: 0%;
  overflow-y: hidden;
  touch-action: none;
  font-size: 18px;
  color: white;


}

.page-intro-portraitMode p {
  width: 90%;
  margin-top: 40px;
}


.introMagic {
  /* display: none; */
  opacity: 0;
  width: 100%;
  overflow-x: hidden;
  height: 46vw;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -10;
  background-image: linear-gradient(to right, #924FAE, #7C58D4);
}

.introMagicPlaceholder {
  width: 100%;
  overflow-x: hidden;
  height: 46vw;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -10;
  background-image: linear-gradient(to right, #924FAE, #7C58D4);
}


.intro-introBG {

  width: 100%;
  /* height: 200%; */
  /* transition: 2s; */
  position: absolute;
  bottom: -0px;
  /* background-color: red; */
  /* transition: 2s; */
}


.intro-layer0 {
  /* display: none; */
  width: 100%;
  height: 125%;
  position: absolute;
  top: -13.7vw;
  left: 0px;
  /* background-color: yellow; */
  /* transition: 2s; */
  z-index: 2;
  /* display: none; */


}

.intro-layer1 {
  /* display: none; */

  width: 87.5%;
  height: 114%;
  position: absolute;
  top: -11.3vw;
  right: 0px;
  /* background-color: yellow; */
  /* transition: 2s; */
  z-index: 1;


}

.intro-lightHouse {
  width: 92%;
  height: 122%;
  position: absolute;
  top: -5vw;
  left: 8%;
  /* background-color: yellow; */
  /* transition: 2s; */
  z-index: 0;

}

.intro-title {
  /* display: none; */
  opacity: 0;
  width: 100%;
  position: absolute;
  bottom: 47%;
  left: 2%;
  /* background-color: yellow; */
  /* transition: 2s; */
  color: white;
  font-size: 4vw;
  /* background-color: red; */
  text-align: center;
  font-family: Inter;
  font-weight: 900;
  z-index: 0;
  background: -webkit-linear-gradient(right, #ad79c7, #ffffff);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

}


.intro-title-portraitMode {
  /* display: none; */
  width: 100%;
  /* background-color: yellow; */
  /* transition: 2s; */
  color: white;
  font-size: 26px;
  margin-bottom: 48px;
  /* background-color: red; */
  text-align: center;
  font-family: Inter;
  font-weight: 900;
  z-index: 0;
  background: -webkit-linear-gradient(right, #ad79c7, #ffffff);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

}


/* Intro slide animation */

/* .introSlide {
  position: fixed;
  opacity: 0;
  width: 100vw;
  height: 100vh;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  z-index: 9000;
} */

.introSlideItem {
  opacity: 0;
  /* height: 100%;
  width: 100%; */
  /* background-color: pink; */

}


.someImage {
  width: 200px;
  height: 100px;
  background-color: red;

}

.page-intro p {
  text-align: center;
  font-size: 2.4vmin;
  max-width: 90vw;
  line-height: 1.3;
  /* background-color: red; */
}

.bang {
  position: absolute;
  right: -6vh;
  top: -1vh;
  width: 5vmin;
}

.introEmoji01 {
  /* width: 59px; */
  width: min(4.5vw, 8vh);
  position: absolute;
  top: 35%;
  left: 23%;

}

.introEmoji02 {
  /* width: 45px; */
  width: min(3.2vw, 6vh);
  position: absolute;
  bottom: 2%;
  right: 33%;

}

.introEmoji03 {
  /* width: 46px; */
  width: min(3.3vw, 6.1vh);
  position: absolute;
  top: 35%;
  right: -5%;

}

.introEmoji01:hover,
.introEmoji02:hover,
.introEmoji03:hover {
  animation: shake 0.5s;
  animation-iteration-count: 1;
}


.neonMic {
  /* width: 265px; */
  /* width: min(calc(6.2vw + 24.8vh), 280px); */
  /* width: calc(8vw * 1.8);
  height: calc(8vw * 3.5); */
  width: calc(min(8vw, 15vh) * 1.8);
  height: calc(min(8vw, 15vh) * 3.5);
  position: absolute;
  right: 0vw;
  top: 0vh;
  opacity: 0;

  /* background-color: green; */
}

.neonHeart {
  /* width: 125px; */
  /* width: min(calc(5.5vw + 22vh), 225px); */
  position: absolute;
  /* width: 160px;
  height: 250px; */
  width: calc(min(7vw, 13vh) * 1.6);
  height: calc(min(7vw, 13vh) * 2.6);
  left: 0vw;
  bottom: 0vh;
  opacity: 0;

  /* background-color: green; */
}

.slideTitle {
  font-size: calc(2.5*(min(1.2vw, 2.4vh, 22px)));
  font-weight: 700;
  width: 80vw;
  margin-bottom: 7vh;
}



.page-intro-slide1-columnContainer {
  display: flex;
  flex-direction: row;
  width: 80vw;
  height: 50vh;
  /* background-color: green; */
  justify-content: center;
  gap: 7%;
  font-size: var(--introFontsizeReg);
  line-height: var(--introLineHeightReg);
}

.page-intro-slide1-column {
  display: flex;
  flex-direction: column;
  width: 24%;
  gap: 12%;
  position: relative;
  /* background-color: red; */
}

.page-intro-slide1-column-gifContainer {
  /* width: 164px; */
  /* width: min(calc(4vw + 16vh), 164px); */
  height: 44%;
  /* background-color: yellow; */
}

.introGif {
  /* width: 164px; */
  /* width: min(calc(4vw + 16vh), 164px); */
  max-height: 100%;
  max-width: 100%;
  border-radius: 50%;
  border: 1px solid black
}

.page-intro-slide1-column-text {
  /* width: 164px; */
  /* width: min(calc(4vw + 16vh), 164px); */
  height: 44%;
  /* background-color: yellow; */
}

.page-intro-slide1-border {
  height: 100%;
  border-right: 1px dashed white;
}

.page-intro-slide2-timelineContainer {
  height: 80vh;
  width: 86vw;
  display: flex;
  flex-direction: column;
  margin-top: 5%;
  /* background-color: yellow; */
}

.page-intro-slide2-timeline-imagesRow {
  width: 100%;
  height: 40%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;

}

.page-intro-slide2-timeline-imageColumn {
  height: 100%;
  display: flex;
  align-items: end;
  justify-content: space-evenly;
  /* background-color: red; */
}

/* .page-intro-slide2-timeline-image {
  width: 100%;
} */

.page-intro-slide2-timeline-arrowRow {
  width: 100%;
  height: 20%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  /* background-color: green; */

}

.page-intro-slide2-timeline-arrowLine {
  border: 1px solid #CFFFB7;
  width: 94%;
  position: absolute;

}

.page-intro-slide2-timeline-arrow {
  /* width: 40px;
  height: 40px; */
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  border-left: 12px solid #CFFFB7;
  position: absolute;
  right: -12px;
  top: -8px;
}

.page-intro-slide2-timeline-dotColumn {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* background-color: red; */
}

.page-intro-slide2-timeline-arrowDot {
  background-color: #CFFFB7;
  width: 3.7vh;
  height: 3.7vh;
  border-radius: 50%;
  position: absolute;
  top: calc(50%-11px);
  box-shadow: 0px 0px 20px rgba(207, 255, 183, 0.4);
}

.page-intro-slide2-timeline-arrowYear {
  color: #CFFFB7;
  position: absolute;
  top: 68%;
  font-family: Inconsolata;
  font-size: min(1.1vw, 2.08vh, 16px);
  line-height: min(1.18vw, 2.2h, 16.78px);
  /* background-color: green; */


}




.page-intro-slide2-timeline-textRow {
  width: 100%;
  height: 40%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  font-family: Inconsolata;
  font-size: min(1.1vw, 2.08vh, 16px);
  line-height: min(1.18vw, 2.2h, 16.78px);


}

.page-intro-slide3-container {
  width: 80vw;
  height: 80vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10%;
  position: relative;
  /* background-color: red; */
}

.page-intro-slide3-text {
  width: 40%;
  font-size: var(--introFontsizeReg);
  line-height: var(--introLineHeightReg);
  /* background-color: red; */
  z-index: 10;
}

.page-intro-slide3-image {
  width: 66%;
  display: flex;
  align-items: center;
  position: absolute;
  top: calc(50vh - height/2);
  right: 0vw;
  border-radius: 10%;
  opacity: 0;
  /* background-color: green; */

}

.greenBubble {
  display: inline-flex;
  align-items: center;
  background-color: #24FF00;
  color: black;
  font-family: Inconsolata;
  font-size: min(1.1vw, 2.08vh, 16px);
  height: min(1.55vw, 3.2vh, 22px);
  padding-left: min(0.55vw, 1.04vh, 8px);
  padding-right: min(0.55vw, 1.04vh, 8px);
  border-radius: min(0.55vw, 1.04vh, 8px);
  border: 1px solid black;
}

.page-intro-slide4-container {
  width: 100vw;
  height: 80vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 3%;
  /* background-color: red; */
}


.page-intro-slide4-left {
  width: 36%;
  height: 100%;
  display: flex;
  align-items: center;

  /* background-color: green; */

}

.page-intro-slide4-middle {
  width: 33%;
  font-size: var(--introFontsizeReg);
  line-height: var(--introLineHeightReg);
  /* background-color: green; */

}

.page-intro-slide4-right {
  width: 20%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;

  /* background-color: green; */

}

.page-intro-slide5-container {
  width: 85vw;
  height: 50vh;
  display: flex;
  flex-direction: row;
  align-items: center;
}


.page-intro-slide5-leftSide {
  opacity: 0;
  width: 50%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  /* background-color: red; */

}

.page-intro-slide5-rightSide {
  opacity: 0;
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  position: relative;
  /* background-color: red; */

}

.page-intro-slide5-quote {
  font-size: var(--introFontsizeReg);
  line-height: var(--introLineHeightReg);
  width: 90%;
}

.page-intro-slide5-source {
  font-size: calc(0.8*(min(1.2vw, 2.4vh, 22px)));
  line-height: calc(0.8*(min(1.9vw, 3.5vh, 28px)));
  color: rgba(255, 255, 255, 0.5);
  font-style: italic;
  width: 90%;

}

.page-intro-slide5-quoteName {
  font-size: calc(0.8*(min(1.2vw, 2.4vh, 22px)));
  line-height: calc(0.8*(min(1.9vw, 3.5vh, 28px)));
  width: 90%;

}

.quotationMarkOpen {
  position: absolute;
  top: -0vh;
  left: -1vw;
  font-size: calc(6.4*(min(1.2vw, 2.4vh, 22px)));
}

.quotationMarkClose {
  position: absolute;
  top: 20vh;
  right: -1vw;
  font-size: calc(6.4*(min(1.2vw, 2.4vh, 22px)));
}

.page-intro-slideShow-container {
  width: 41%;
  /* height: 60vh; */
  display: flex;
  flex-direction: column;
  /* background-color: yellow; */
}

.page-intro-slideShow-left {
  width: 100%;
  /* height: 100%; */
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: black;
  margin-top: 5%;

  /* background-color: yellow; */
}

.page-intro-slideShow-leftText {
  font-size: var(--introFontsizeReg);
  line-height: var(--introLineHeightReg);
  font-weight: 500;
  font-family: Inconsolata;
  color: white;
  padding-left: 8%;
  padding-right: 8%;
  padding-top: 4%;
  padding-bottom: 4%;

  /* background-color: yellow; */
}

.page-intro-slideShow-right {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.page-intro-slideShow-rightCaption {
  width: 100%;
  max-width: 100%;
  font-size: var(--introFontsizeReg);
  line-height: var(--introLineHeightReg);
  font-weight: 500;
  font-family: Inconsolata;
  color: white;
  margin-top: 8px;
  z-index: 20;
  /* padding-left: 8%;
  padding-right: 8%; */
  padding-top: 4%;
  padding-bottom: 4%;
  /* background-color: red; */
  text-align: center;



}

.page-intro-slide6-container {
  width: 85vw;
  height: 60vh;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  font-size: var(--introFontsizeReg);
  line-height: var(--introLineHeightReg);
  /* background-color: red; */
}

.page-intro-slide6-leftSide {
  opacity: 0;
  width: 38%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  /* background-color: yellow; */
  align-items: flex-start;
  position: relative;

}

.page-intro-slide6-middleSide {
  opacity: 0;
  width: 24%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  /* background-color: yellow; */
}

.page-intro-slide6-iPhoneContainer {
  max-width: 100%;
  max-height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  /* background-color: red; */
}

.page-intro-slide6-rightSide {
  opacity: 0;
  width: 38%;
  height: 100%;
  gap: 3%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  /* background-color: green; */

}

.page-intro-slide6-regText {
  width: 80%;
  opacity: 0;
  /* background-color: red; */
  /* height: 50%; */
}

.page-intro-slide6-regTextAbs {
  position: absolute;
  width: 80%;
  opacity: 0;
}

.page-intro-slide6-shiftBubble {
  /* width: 52px; */
  /* height: 20px; */
  width: 32.2%;
  height: 14.64%;
  /* scale: 0.66; */
  opacity: 0;
  /* background-color: red; */
  position: absolute;
}

.page-intro-slide7-columnContainer {
  width: 80vw;
  height: 60vh;
  display: flex;
  flex-direction: row;
  font-size: var(--introFontsizeReg);
  line-height: var(--introLineHeightReg);
}

.page-intro-slide7-column {
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 4px;
  opacity: 0;

}

.page-intro-slide7-column-row {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
}

.page-intro-slide7-column-row-column1 {
  width: 30%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  /* background-color: red; */
  position: relative;
}


.page-intro-slide7-column-row-column2 {
  width: 50%;
  display: block;
  margin-bottom: auto;
  margin-top: auto;
  /* background-color: yellow; */
}


.page-intro-slide7-icon {
  width: 70%;
  position: absolute;
  left: -19%;
  /* background-color: yellowgreen; */
}


.page-intro-slide8-textSignHappy {
  width: 45vw;
  position: absolute;
  top: 18%;
  left: 20%;
  /* transform: rotateX(45deg); */
  opacity: 0;
  rotate: -6deg;
}

.page-intro-slide8-textSignExploring {
  width: 64.5vw;
  position: absolute;
  top: 48%;
  left: 30%;
  opacity: 0;
  rotate: 6deg;



}

.page-intro-slide8-floatingIcons {
  opacity: 0;
  position: absolute;
  width: 4vw;
}


.slide8-greenBubble {
  display: flex;
  align-items: center;
  opacity: 0;
  position: absolute;
  width: 22vw;
  background-color: #24FF00;
  color: black;
  font-family: Inconsolata;
  /* font-size: min(1.1vw, 2.08vh, 16px); */
  font-size: min(1.1vw, 16px);
  padding: min(0.99vw, 1.872vh, 14.4px);
  border-radius: min(0.55vw, 1.04vh, 8px);
  border: 1px solid black;
}



/* Market Studies */

.page-marketStudies {

  background-image: linear-gradient(to right, #924FAE, #7C58D4);
  /* background-image: url('./media/gradient.mp4');
  background-attachment: fixed;
  background-size: 100%; */
  min-height: calc(100vh - 0px);
  padding-top: 64px;
  font-family: Inter;
  display: flex;
  flex-direction: column;
  width: 100vw;
  align-items: center;
  background-size: 100%;
  overflow-x: hidden;
  color: white;


}

.page-marketStudies-header {
  width: 1040px;
  max-width: 90vw;
  height: 180px;
  margin-bottom: 30px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;

}

.page-marketStudies-header-column1 {
  width: 12%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* background-color: red; */

}

.page-marketStudies-header-column2 {
  width: 30%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

}

.page-marketStudies-header-column2 p {
  margin-top: 12px;

}

.page-marketStudies-header-column3 {
  width: 58%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 50px;
  font-weight: 900;
}

.marketStudies-introPara {
  font-size: 25px;
  line-height: 30.26px;
  width: 640px;
  max-width: 90vw;
  z-index: 20;
  margin-top: 90px;
  margin-bottom: 45px;



}

.marketStudies-regularImage {
  width: 522px;
  max-width: 90vw;
  margin-top: 45px;
  /* margin-bottom: 20px; */
  z-index: 20;


}

.marketStudies-bannerImage {
  width: 1040px;
  max-width: 90vw;
  margin-top: 45px;
  margin-bottom: 20px;
  z-index: 20;


}

.marketStudies-bannerImageLeft {
  max-width: 90vw;
  margin-top: 45px;
  margin-bottom: 20px;
  align-self: flex-start;
  z-index: 20;


}

.marketStudies-bannerImageRight {
  max-width: 90vw;
  margin-top: 45px;
  margin-bottom: 20px;
  align-self: flex-end;
  z-index: 20;

}

.marketStudies-iconTitleContainer {
  margin-top: 120px;
  display: flex;
  flex-direction: row;
  width: 522px;
  max-width: 90vw;
  /* background-color: green; */
  align-items: center;
}


.marketStudies-iconContainer {
  margin-right: 25px;
  /* background-color: green; */
}

.marketStudies-iconImage {
  border-radius: 50%;
  width: 65px;
  border: 3px solid white;
  box-shadow: 1px 1px #000000;
}



.marketStudies-titleText {
  font-size: 20px;
  z-index: 20;
  /* background-color: red; */
}

.marketStudies-regularText {
  width: 522px;
  max-width: 90vw;
  font-size: 20px;
  line-height: 28px;
  margin-top: 45px;
  z-index: 20;
  /* background-color: red; */


}

.marketStudies-mediaCaption {
  width: 522px;
  max-width: 90vw;
  font-size: 13px;
  line-height: 10px;
  font-style: italic;
  color: rgba(255, 255, 255, 0.5);
  /* color: red; */
  margin-top: 10px;
  z-index: 20;


}


.page-videoReels-header {

  font-size: 50px;
  font-weight: 900;
  margin-top: 48px;
  color: white;
  width: 80vw;
}

.page-videoReels p {
  align-self: flex-start;
  margin-left: 10vw;
  color: white;
  max-width: 618px;
  font-size: 20px;
  line-height: 28px;
  margin-top: 28px;

}

.page-videoReels a {
  color: white;
  text-decoration: underline;

}

.page-videoReels-3videos {
  margin-top: 70px;
  width: 80vw;
  height: 25vw;
  display: flex;
  flex-direction: row;
  gap: 5%;
}

.page-videoReels-neonLight {
  /* width: 265px; */
  /* width: min(calc(6.2vw + 24.8vh), 280px); */
  /* width: calc(8vw * 1.8);
    height: calc(8vw * 3.5); */
  width: calc(min(8vw, 15vh) * 1.8*1);
  height: calc(min(8vw, 15vh) * 3.5*1);
  position: fixed;
  right: 0vw;
  top: 0vh;

  /* background-color: green; */
}

.page-videoReels-neonLove {
  /* width: 125px; */
  /* width: min(calc(5.5vw + 22vh), 225px); */
  position: fixed;
  /* width: 160px;
    height: 250px; */
  width: calc(min(7vw, 13vh) * 1.69*1.3);
  height: calc(min(7vw, 13vh) * 1.201*1.3);
  left: 0vw;
  bottom: 0vh;

  /* background-color: green; */
}


.page-credits p {
  align-self: flex-start;
  margin-left: 10vw;
  margin-right: 10vw;
  color: white;
  max-width: min(618px, 90%);
  font-size: 20px;
  line-height: 28px;
  margin-top: 48px;
}

.page-credits a {
  color: white;
  text-decoration: underline;
}

.someDivContainer {

  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0%;
  left: 0%;
  background-color: green;
  display: flex;
}

.someDivText {
  width: 1600px;
  height: 900px;
  aspect-ratio: 16/9;
  font-size: 75px;
  background-color: yellow;
  position: absolute;
  left: calc((100vw - 1600px)/2);
  top: calc((100vh - 900px)/2);

}



/*Media queries */

@media only screen and (max-width: 600px) {


  .navbar-row1 {


    padding-left: 30px;
    padding-right: 30px;


  }

  .sidebarLeft {


    left: -280px;
    height: 100vh;
    width: 280px;
    align-items: left;
    font-size: 0.65rem;
  }

  .sidebarLeft-content {
    margin-left: 40px;
    margin-right: 12px;


  }

  .sidebarLeft-gemic-logo {
    width: calc(0.02*2928px);
    height: calc(0.02*687px);
    /* max-width: 2rem; */
    margin-right: 12px;


  }

  .sidebarLeft-spotify-logo {
    width: calc(0.06*1024);
    height: calc(0.06 *308px);
    margin-left: 12px;
  }

  .page-marketStudies-header {
    flex-direction: column;
    margin-top: 40px;

  }

  .page-marketStudies-header-column1 {
    width: 80%;
    /* background-color: red; */

  }

  .page-marketStudies-header-column2 {
    width: 80%;
    margin-top: 24px;


  }

  .page-marketStudies-header-column2 p {
    margin-top: 4px;

  }



  .page-marketStudies-header-column3 {
    width: 90%;
    font-size: 24px;
    margin-top: 24px;
  }

  .graph-tutorial {

    width: 96vw !important;
    left: calc(4vw / 2) !important;

  }

  .graph-tutorial-dottedLine {

    width: 88vw;
  }

  .graph-helpIcon {
    display: none;
    position: absolute;
    bottom: 12px;
    left: 30px;
    cursor: pointer;
    z-index: 50;
  }

  .sidebarRight {

    right: -83vw;
    width: 83vw;

  }


  .sidebarRight-content {

    margin: 28px;

  }

  .intro-title-nologin {
    font-size: 28px;

  }


  .page-videoReels p {
    margin-left: 5vw;
    margin-right: 5vw;

  }

  .page-credits p {
    margin-left: 5vw;
    margin-right: 5vw;

  }

}


@media only screen and (max-width: 400px) {


  .navbar-row1 {


    padding-left: 25px;
    padding-right: 25px;


  }

  .intro-title-nologin {
    font-size: 24px;

  }

}

@media only screen and (max-width: 340px) {


  .navbar-row1 {


    padding-left: 20px;
    padding-right: 20px;


  }

}


@font-face {
  font-family: Inter;
  src: url('./fonts/Inter.ttf');
}

@font-face {
  font-family: Inconsolata;
  src: url('./fonts/Inconsolata.ttf');
}
